import React from "react";

interface ComingSoonProps {}

const ComingSoon: React.FC<ComingSoonProps> = () => {
  return (
    <div className="h-screen flex flex-col items-center justify-center bg-gray-100">
      <h1 className="text-5xl font-bold text-center mb-8">Coming Soon</h1>
      <p className="text-xl text-gray-600 text-center">
        We're launching something amazing. Stay tuned!
      </p>
    </div>
  );
};

export default ComingSoon;
